define("torii-azure-ad2-provider/torii-providers/azure-ad2-oauth2", ["exports", "torii/providers/oauth2-code", "torii/configuration", "@ember/object"], function (_exports, _oauth2Code, _configuration, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This class implements authentication against Azure AD 2.0
   * using the OAuth2 authorization flow in a popup window.
   */
  var _default = _exports.default = _oauth2Code.default.extend({
    name: 'azure-ad2-oauth2',
    baseUrl: (0, _object.computed)('tenantId', function () {
      return `https://login.microsoftonline.com/${this.get('tenantId')}/oauth2/v2.0/authorize`;
    }),
    responseParams: Object.freeze(['code', 'state']),
    init() {
      this._super(...arguments);
      // Overwrite the default settings of the superclass Oauth2 provider.
      // If we don't use set, but set the property directly on the class,  Ember will merge both arrays
      this.set('requiredUrlParams', ['response_type', 'client_id', 'redirect_uri', 'state', 'scope']);
      this.set('optionalUrlParams', ['response_mode', 'prompt', 'login_hint', 'domain_hint']);
    },
    tenantId: (0, _configuration.configurable)('tenantId', 'common'),
    scope: (0, _configuration.configurable)('scope', 'offline_access User.Read'),
    responseMode: (0, _configuration.configurable)('responseMode', 'query'),
    prompt: (0, _configuration.configurable)('prompt', null),
    login_hint: (0, _configuration.configurable)('login_hint', null),
    domain_hint: (0, _configuration.configurable)('domain_hint', null),
    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      return this._super();
    })
  });
});